@import './normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;700&display=swap');

@viewport {
  width: 100vw;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Overpass Mono', monospace;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

strong {
  font-weight: 700;
}

p {
  margin: 1.25rem 0;
  max-width: 50rem;
}

.nav {
  display: flex;
}

.nav-link {
  color: #999999;
  cursor: pointer;
  padding: 0.625rem;
  text-decoration: none;
}

.nav-link.active {
  color: #eeeeee;
}

.nav-link:hover {
  color: #eeeeee;
  text-decoration: underline;
}

.topbar {
  align-items: center;
  background-color: #111111;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.625rem 0.625rem 0 0.625rem;
}

.topbar-title {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
  margin: 0 auto 0 0;
  padding: 0.625rem;
  text-decoration: none;
  text-transform: uppercase;
}

.topbar-logo {
  padding: 0.625rem;
}

.topbar-logo img {
  color: #ffffff;
  display: block;
  fill: currentColor;
  height: 1.25rem;
  width: auto;
}

.primary-nav {
  background-color: #111111;
  display: flex;
  justify-content: space-between;
  padding: 0 0.625rem 0.625rem 0.625rem;
  text-transform: uppercase;
}

.secondary-nav {
  background-color: #222222;
  padding: 0.3125rem 0.625rem;
}

.language-toggle {
  color: #999999;
  font-size: 0.75rem;
  font-weight: 700;
}

.language-toggle a {
  display: inline-block;
  cursor: pointer;
  padding: 0.625rem;
}

.language-toggle a.active {
  color: #CCCCCC;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.sidebar {
  background-color: #ffffff;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1.25rem;
}

.sidebar-heading {
  font-size: 1rem;
  line-height: 1.5625rem;
  margin: 1.25rem 0 1.5625rem 0;
}

.main {
  background-color: #eeeeee;
  flex-grow: 1;
  padding: 0 1.25rem;
}

.main-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  margin: 1.25rem 0;
  text-transform: uppercase;
}

.control-group {
  align-items: left;
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;
}

.control-label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 0.625rem 0;
  text-transform: uppercase;
}

.control-info {
  margin: 0.625rem 0;
}

.control-select {
  appearance: none;
  background-color: #ffffff;
  background-image: url('../../assets/icons/chevron-down-sharp.svg');
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.9375rem);
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
  margin: 0.625rem 0;
}

.section {
  margin: 1.875rem 0;
}

.section-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 1.25rem 0;
  text-transform: uppercase;
}

.chart-info {
  background-image: url('../../assets/icons/caret-down-outline.svg');
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-left: auto;
  max-width: 360px;
  padding-left: 1.75rem;
  text-transform: uppercase;
}

.chart-container {
  background-color: #ffffff;
  margin: 0 -1.25rem;
}

.chart {
  display: block;
  height: auto;
  width: 100%;
}

.chart-axis {
  font-family: 'Overpass Mono', monospace;
}

.chart-axis .domain,
.chart-axis .tick line {
  opacity: 0.05;
}

.chart-axis .tick text {
  opacity: 0.75;
}

.chart-axis .domain {
  display: none;
}

.chart-zero-line {
  pointer-events: none;
}

.chart-rule {
  pointer-events: none;
}

.chart-histogram .chart-series-line {
  opacity: 1;
  stroke-width: 2px;
}

.chart-histogram .chart-series-area {
  opacity: 0.1;
}

.chart-histogram .chart-grid-x {
  display: none;
}

.chart-waterfall .chart-axis-x .domain {
  display: block;
}

.chart-waterfall .chart-shapes:hover .chart-series-rect {
  opacity: 0.1;
}

.chart-waterfall .chart-shapes .chart-series-rect:hover {
  opacity: 1;
}

.chart-diverging-stacked-area .chart-axis-y,
.chart-coordinates .chart-axis-y {
  display: none;
}

.chart-diverging-stacked-area .chart-grid-y,
.chart-coordinates .chart-grid-y {
  display: none;
}

.chart-diverging-stacked-area .chart-shapes:hover .chart-series .chart-series-area {
  opacity: 0.1;
}

.chart-diverging-stacked-area .chart-shapes .chart-series:hover .chart-series-area {
  opacity: 1;
}

.chart-coordinates .chart-shapes .chart-series .chart-series-line {
  stroke-width: 2px;
}

.chart-coordinates .chart-shapes:hover .chart-series .chart-series-line {
  opacity: 0.25;
  stroke-width: 1px;
}

.chart-coordinates .chart-shapes .chart-series:hover .chart-series-line {
  opacity: 1;
  stroke-width: 6px;
}

.feature-list {
  background-color: #ffffff;
  border-radius: 0.3125rem;
  counter-reset: feature-list;
  padding: 0.625rem 1.25rem;
}

.feature-list h3 {
  margin: 0.625rem 0;
}

.feature-list dl {
  margin: 1.25rem 0;
  padding: 0 0 0 3.125rem;
}

.feature-list dt {
  counter-increment: feature-list;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0.625rem 0 0.25rem 0;
  padding: 0;
}

.feature-list dt::before {
  content: counter(feature-list) ".";
  display: inline-block;
  color: #999999;
  font-weight: normal;
  margin: 0 0.625rem 0 -3.125rem;
  padding: 0;
  text-align: right;
  width: 2.5rem;
}

.feature-list dd {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin: 0.25rem 0 0.625rem 0;
}

.faq {
  margin: 1.875rem 0;
  max-width: 50rem;
}

.faq dt {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.625rem 0;
}

.faq dt:before {
  content: "Q: ";
}

.faq dd {
  margin: 0 0 1.25rem 0;
}

.faq dd:before {
  content: "A: ";
}

.text-muted {
  color: #999999;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .topbar-logo {
    flex: 0 0 auto;
    overflow: hidden;
    width: 1.25rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .control-group {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.25rem -0.625rem;
  }

  .control-label,
  .control-info,
  .control-select {
    margin: 0 0.625rem;
    width: 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    flex-direction: row-reverse;
  }

  .topbar {
    padding-top: 1.25rem;
  }

  .topbar,
  .primary-nav,
  .secondary-nav {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .topbar-title {
    font-size: 1.25rem;
    letter-spacing: 0.2em;
  }

  .main {
    padding: 0rem 1.875rem;
  }

  .sidebar {
    max-width: 360px;
    padding: 0rem 1.875rem;
  }

  .chart-container {
    border-radius: 0.3125rem;
    margin: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .topbar,
  .primary-nav,
  .secondary-nav {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .main {
    padding: 0.625rem 2.5rem;
  }

  .sidebar {
    max-width: 480px;
    padding: 0.625rem 2.5rem;
  }
}
